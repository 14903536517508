import { AuthProvider } from 'components/AuthProvider'
import React from 'react'
import PublicHeader from 'components/public/PublicHeader'
import PublicFooter from 'components/public/PublicFooter'

type Props = {
  children: React.ReactElement | React.ReactElement[]
  isStatic: boolean
  header?: boolean
  footer?: boolean
}

const PublicLayout = ({
  isStatic,
  header = true,
  footer = true,
  children
}: Props) => {
  return (
    <AuthProvider isStatic={isStatic}>
      <div className="bg-white min-h-screen bg-gray-50">
        <div className="relative overflow-hidden">
          {header && <PublicHeader />}
          <main>{children}</main>
          {footer && <PublicFooter />}
        </div>
      </div>
    </AuthProvider>
  )
}

export default PublicLayout
