import React from 'react'
import Link from 'next/link'

const footerNavigation = {
  solutions: [
    { name: 'Comments Widget', href: '/' },
    { name: 'Tasks (Coming soon)', href: '' }
  ],
  support: [
    { name: 'Pricing', href: '/pricing' },
    { name: 'Documentation', href: '/docs' }
    // { name: 'Guides', href: '#' },
    // { name: 'API Status', href: '#' }
  ],
  // company: [
  //   { name: 'About', href: '#' },
  //   { name: 'Blog', href: '#' },
  //   { name: 'Jobs', href: '#' },
  //   { name: 'Press', href: '#' },
  //   { name: 'Partners', href: '#' }
  // ],
  legal: [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' }
  ]
}

export default function PublicFooter() {
  return (
    <footer aria-labelledby="footer-heading" className="bg-gray-50">
      <h2 className="sr-only" id="footer-heading">
        Footer
      </h2>
      <div className="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8 pb-24">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img
              alt="Company name"
              className="h-10"
              src="https://tailwindui.com/img/logos/workflow-mark-gray-300.svg"
            />
            <p className="text-gray-500 text-base">
              Dashing tools for dashboards
            </p>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Tools
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link href={item.href} passHref>
                        <a className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Support
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <Link href={item.href} passHref>
                        <a className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8 justify-start">
              <div className="md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link href={item.href} passHref>
                        <a className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
