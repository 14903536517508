import { useAuth } from 'components/AuthProvider'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import logo from 'images/logo.svg'

const navigation = [
  { name: 'Product', href: '/' },
  { name: 'Documentation', href: '/docs' },
  { name: 'Pricing', href: '/pricing' }
]

export default function PublicHeader() {
  const { operator } = useAuth()
  return (
    <Popover as="header" className="relative z-10">
      <div className="bg-gray-900 pt-4 pb-4">
        <nav
          aria-label="Global"
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <span className="sr-only">Dashing Kit</span>
                <div className="h-8 w-8 sm:h-10 sm:w-10">
                  <Image
                    alt="Dashing Kit Logo"
                    layout="responsive"
                    src={logo}
                  />
                </div>
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon aria-hidden="true" className="h-6 w-6" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden space-x-8 md:flex md:ml-10">
              {navigation.map((item) => (
                <Link key={item.name} href={item.href} passHref>
                  <a className="text-base font-medium text-white hover:text-gray-300">
                    {item.name}
                  </a>
                </Link>
              ))}
            </div>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-6">
            {operator ? (
              <Link href="/dashboard" passHref>
                <a className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700">
                  Open Dashboard
                </a>
              </Link>
            ) : (
              <Link href="/login" passHref>
                <a className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700">
                  Sign in / Sign up
                </a>
              </Link>
            )}
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
          focus
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <div className="h-8 w-8">
                  <Image
                    alt="Dashing Kit Logo"
                    layout="responsive"
                    src={logo}
                  />
                </div>
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                  <span className="sr-only">Close menu</span>
                  <XIcon aria-hidden="true" className="h-6 w-6" />
                </Popover.Button>
              </div>
            </div>
            <div className="pt-5 pb-6">
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    href={item.href}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              {operator ? (
                <>
                  <div className="mt-6 px-5">
                    <a
                      className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-pink-500 to-purple-600 text-white font-medium hover:from-pink-600 hover:to-purple-700"
                      href="/dashboard"
                    >
                      Open Dashboard
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-6 px-5">
                    <a
                      className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-pink-500 to-purple-600 text-white font-medium hover:from-pink-600 hover:to-purple-700"
                      href="/signup"
                    >
                      Try for free
                    </a>
                  </div>
                  <div className="mt-6 px-5">
                    <p className="text-center text-base font-medium text-gray-500">
                      Existing customer?{' '}
                      <a
                        className="text-gray-900 hover:underline"
                        href="/login"
                      >
                        Login
                      </a>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
